/* FIXME: for whatever reason, the comments div seems to reduce to display none on Safari 15? Unsure if browser bug, or tailwind bug or both. */
.comments {
  @apply flex flex-col;
}

.comment {
  @apply max-w-3/4;
}

.comment .author {
  @apply px-4 py-1 truncate;
}

.comment .body {
  /* apply text-left to make sure always LTR */
  @apply inline-block py-2 px-4 bg-gray-300 mb-2 rounded-xl ring-1 ring-gray-400 text-left;
}

.comment--authored {
  /* apply text-right to make sure blocks are right-most */
  @apply self-end text-right;
}

.comment--authored .body {
  @apply bg-primary-300 ring-primary-400 text-white;
}
