.avatar {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.6rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  background: gray;
}
