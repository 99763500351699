/* Tags */
.tag-search-button {
  margin-left: var(--half-default-space);
}

.search-box {
  padding: var(--default-space) 0;
  padding-left: var(--half-default-space);
  display: flex;
  overflow: scroll;
}

.tag-count {
  margin-left: var(--half-default-space);
}

.overflow-scroll {
  overflow: hidden;
}