/* PlanCell */

.plan-cell {
  position: relative;
  margin-bottom: var(--default-space);
}

.plan-cell-menu-toggle {
  position: absolute;
  top: var(--half-default-space);
  right: var(--default-space);
}

.plan-cell-location,
.plan-cell-period {
  color: var(--light-2);
}

.plan-cell-location {
  margin-right: var(--half-default-space);
}

/* PlanList */

.plan-list--empty,
.plan-list--loading {
  margin: var(--default-space) 0;
}
