:root {
  --white: hsl(0, 0%, 100%);
  --light-1: hsl(0, 0%, 95%);
  --light-2: hsl(0, 0%, 71%);
  --gray: hsl(0, 0%, 50%);
  --dark-2: hsl(0, 0%, 33%);
  --dark-1: hsl(0, 0%, 15%);
  --black: hsl(0, 0%, 0%);
  --default-space: 1rem;
  --half-default-space: 0.5rem;
  --twice-default-space: 2rem;
  --primary-100: #e7e0ff;
  --primary-200: #ae9ef8;
  --primary-300: #7b65e5;
  --primary-400: #584d87;
  --primary-500: #383452;
}

body {
  height: 100vh;
  width: 100vw;

  background: var(--light-1);
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.bp5-card {
  padding: var(--default-space);
}

/* UTILS */

/* these reimplement tailwind's `bg-transparent` + `shadow-none` respectively,
adding !important because blueprint's input styles have higher-specificity than just using the tailwind provided utils.
If we migrate off blueprint we can probably get rid of these. */
.bg-transparent {
  background: none !important;
}

.shadow-none {
  box-shadow: none !important;
}

.bp5-button.bp5-intent-primary,
.bp5-tag.bp5-intent-primary {
  background-color: var(--primary-300);
}

.bp5-button.bp5-intent-primary:hover,
.bp5-tag.bp5-intent-primary:hover {
  background-color: var(--primary-400);
}

.bp5-button.bp5-intent-primary.bp5-disabled,
.bp5-tag.bp5-intent-primary.bp5-disabled {
  background-color: var(--primary-200);
}

.bp5-button.bp5-intent-primary:active,
.bp5-tag.bp5-intent-primary:active {
  background-color: var(--primary-500);
}

.bp5-button.bp5-minimal.bp5-intent-primary {
  background-color: transparent;
  color: var(--primary-300);
}

.bp5-button.bp5-minimal.bp5-intent-primary:hover {
  background-color: var(--primary-100);
  color: var(--primary-500);
}

.bp5-tag.bp5-minimal.bp5-intent-primary {
  background-color: var(--primary-100);
  color: var(--primary-500);
}

.bp5-tag.bp5-minimal.bp5-intent-primary:focus {
  outline-color: var(--primary-300);
}

.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:hover {
  background-color: var(--primary-100);
}

.bp5-tag.bp5-minimal.bp5-intent-primary.bp5-interactive:active {
  background-color: var(--primary-200);
}

.bp5-dialog {
  @apply w-auto max-w-3xl;
}

a {
  color: var(--primary-300);
}

a:hover {
  color: var(--primary-400);
}
