.planitem {
  margin-bottom: var(--default-space);
  display: flex;
  flex-direction: row;
  align-items: top;
}

.planitem > .body {
  flex: 1;
}

.planitem > .body > .bp5-heading {
  margin-bottom: 0;
}

.planitem > .body > .tags {
  line-height: calc(var(--default-space) + var(--half-default-space));
}

.planitem > .body > .tags,
.planitem > .body > .committed-users {
  margin-top: var(--half-default-space);
}

.planitem > .delete-button {
  margin-right: var(--half-default-space);
}
.planitem > .commit-button {
  margin-left: var(--half-default-space);
}

.committed-users > .avatar {
  position: relative;
  margin-right: calc(-1 * var(--half-default-space));
  transition: all ease-in-out 0.1s;
}

.committed-users > .avatar:hover {
  margin-right: initial;
}
