/* Join Page*/
/*@import url('https://fonts.googleapis.com/icon?family=Material+Icons');*/
.join-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  margin-top: 20vh;
  font-size: 28px;
}

/*.join-container .tag{
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px;
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.tag i {
  font-size: 16px;
  margin-left: 5px;
}*/

.plan-title {
  font-size: 65px;
  color: var(--dark-1);
  font-weight: 700;
  margin-bottom: 10vh;
}

.invite-button {
  font-size: 20px;
  height: 50px;
  border: none;
  border-radius: 5px;
  color: white;
  margin-bottom: 1vw;
  width: 170px;
}

.invite-button:hover {
  cursor: pointer;
  background: #1790db;
}

.cancel-button {
  font-size: 20px;
  height: 70px;
  width: 200px;
  background: transparent;
  border: none;
  border-radius: 5px;
  color: red;
}

.cancel-button:hover {
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  border: 2px solid grey;
}
